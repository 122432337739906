import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import { Link } from "gatsby"
import { BiArrowBack } from "react-icons/bi"
const Feedback = () => {
  return (
    <Layout>
      <Seo
        title="Amazon Customers Feedback"
        description="Get notified when you received a bad feedback on amazon"
      />
      <Container fluid className="px-0">
        <div className="text-center " style={{ position: "relative" }}>
          <Styledh1 className="display-4">Amazon Customers Feedback</Styledh1>
          <StaticImage
            className="mt-2"
            src="../../assets/images/featuresbg.jpg"
            alt="Amazon FBA App Features"
            placeholder="tracedSVG"
            layout="fullWidth"
            aspectRatio={6 / 1}
            style={{ zIndex: "-1" }}
          ></StaticImage>
        </div>
        <Container className="p-5">
          <Row className="mx-auto">
            <Col>
              <Row className="text-center">
                <Col sm={12} className="pb-5">
                  <StaticImage
                    src="../../assets/images/azszero-Feedback-01.png"
                    alt="Actions for Split Testing"
                    placeholder="tracedSVG"
                    layout="constrained"
                    //aspectRatio={1 / 1}
                    height="600"
                  ></StaticImage>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={8} className="pb-5">
                  <ul>
                    <li>
                      Get notified when you receive bad feedback (3 stars or
                      less) on any of your seller's marketplace.
                    </li>
                    <li>
                      azszero will link you directly to the marketplace in order
                      to try to resolve the feedbacks.
                    </li>
                    <li>
                      azszero will organize your customer's feedback to see
                      which ones were resolved and which that could not be
                      resolved.
                    </li>
                    <li>
                      azszero will suggest how to contact the buyer trying to
                      remove the feedback in case it was unresolvable.
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Link to="/Amazon_FBA_Seller_App_Features">
                <BiArrowBack />
                &nbsp;&nbsp;Back to Features Page
              </Link>{" "}
            </Col>
          </Row>
        </Container>
      </Container>
    </Layout>
  )
}
const Styledh1 = styled.h1`
  padding: 30px;
  color: slategray;
  margin-bottom: -50px;
  z-index: 10;
  font-weight: 700;
`
export default Feedback
